.departmentcard {
  margin-top: 20px;
  padding: 3px;
  border: 1px solid #ccc;
  height: 200px;
  width: 200px;
}

.card {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 1000px;
}

.button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

/* In your component's CSS file or a separate CSS file */
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-pagination .MuiTablePagination-select {
  margin-right: 5px;
  margin-top: -11px;
  position: relative;
}

.custom-pagination .MuiTablePagination-select::after {
  content: "▼"; /* Add a down arrow icon */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-pagination .MuiSelect-icon {
  display: none; /* Hide the default select icon */
}

.custom-pagination .MuiTablePagination-actions {
  margin-right: 8px;
  margin-top: -15px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
