/* In your component's CSS file or a separate CSS file */
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-pagination .MuiTablePagination-select {
  margin-right: 5px;
  margin-top: -11px;
  position: relative;
}

.custom-pagination .MuiTablePagination-select::after {
  content: "▼"; /* Add a down arrow icon */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-pagination .MuiSelect-icon {
  display: none; /* Hide the default select icon */
}

.custom-pagination .MuiTablePagination-actions {
  margin-right: 8px;
  margin-top: -15px;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Other styles for the parent container */
}

.card {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.column-cell {
  width: 0%;
  max-width: 225px;
}

/* In your component's CSS file or a separate CSS file */
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-pagination .MuiTablePagination-select {
  margin-right: 5px;
  margin-top: -11px;
  position: relative;
}

.custom-pagination .MuiTablePagination-select::after {
  content: "▼"; /* Add a down arrow icon */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-pagination .MuiSelect-icon {
  display: none; /* Hide the default select icon */
}

.custom-pagination .MuiTablePagination-actions {
  margin-right: 8px;
  margin-top: -15px;
}
