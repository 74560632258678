.fc .fc-daygrid-day.fc-day-today {
  background-color: #8de2d9 !important;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: red !important;
}

.custom-event-container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
