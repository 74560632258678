.fc-day-sun {
  background-color: #ffcccc;
}

/* .fc-day-today {
    background: red !important;
    border: none !important;
}; */

/* #fc-day-today{
    background: red !important;
    border: none !important;
};
.custom-today-number {
    font-size: 1.5em;
    font-weight: bold; 
}; */

/* .fc-day-today {
    background-color:#ffcccc;
  }
   */
/* .fc-more-popover .fc-scroller {
    max-height: 100px;
    overflow-y: auto;
  } */

.fc-popover .fc-scroller {
  max-height: 100px; /* Adjust based on your preference */
  overflow-y: auto;
}
.circle-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4c9d97; /* Adjust color as per your requirement */
}
